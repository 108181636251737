<template>
  <div class="lppledge-style">
    <div class="outbox center-center">
      <el-card class="box-card" v-loading="isloading">
        <div class="box1 between-center">
          <div class="b1-t1"></div>
          <div class="b1-t2">LP</div>
          <img
            src="@/assets/img/img32.png"
            alt=""
            class="b1-t1"
            @click="golist"
          />
        </div>
        <van-divider>
          {{$t('text.t297')}}
        </van-divider>
        <div class="box2">
          <van-tabs
            title-active-color="#323232"
            title-inactive-color="#909090"
            v-model="active"
            animated
            color="#323232"
            :line-width="80"
          >
            <van-tab :title="$t('text.t10')">
              <div class="box2-b1">
                <div class="box7 start-center">
                  <input type="number" v-model="lpnum" :placeholder="$t('text.t298')" class="box7-inp" @blur="blurInput" @input="getomtNum" />
                </div>
                <div class="box9">≈{{omtNum}} OMT</div>
                <div class="box10">
                  <div class="tt1">{{$t('text.t299')}}</div>
                  <div>
                    <div class="tt2 between-center" @click="changeType(item.type)" v-for="item in tlist" :key="item.type" :class="tac==item.type?'tt2-ac':''">
                      <div>{{item.times}}{{$t('text.t185')}}</div>
                      <div>{{item.bili}}%</div>
                    </div>
                  </div>
                </div>
                <div class="box6 center-center" @click="lpPledge">{{$t('text.t10')}}</div>
              </div>
            </van-tab>
            <van-tab :title="$t('text.t311')">
              <div class="box2-b1">
                <div class="box10">
                  <div class="tt3 between-center">
                    <div>{{$t('text.t300')}}</div>
                    <div>{{info.release_total}}</div>
                  </div>
                  <div>
                    <div class="tt2 between-center">
                      <div>{{$t('text.t301')}}</div>
                      <div>{{info.static_release}}</div>
                    </div>
                    <div class="tt2 between-center">
                      <div>{{$t('text.t302')}}</div>
                      <div>{{info.share_release}}</div>
                    </div>
                    <div class="tt2 between-center">
                      <div>{{$t('text.t303')}}</div>
                      <div>{{info.node_release}}</div>
                    </div>
                  </div>
                </div>
                <div class="box6 center-center" @click="goAhead('/lpfxlist')">{{$t('text.t304')}}</div>
                <div class="box6 center-center" @click="goAhead('/lpnode')">{{$t('text.t305')}}</div>
              </div>
            </van-tab>
          </van-tabs>
        </div>
      </el-card>
    </div>
    <!-- <div class="outbox center-center">
      <el-card class="box-card">
        <div class="bc-box1">
          <div class="t1 between-center">
            <div class="left">{{$t('text.t306')}}</div>
            <div class="right">{{bilis}}%</div>
          </div>
          <div class="t2">
            <van-progress track-color="#fff" :percentage="bilis" :show-pivot="false" color="#323232" stroke-width="16" />
          </div>
          <div class="t3">{{$t('text.t307')}}</div>
        </div>
      </el-card>
    </div> -->
  </div>
</template>
<script>
import {mapState} from 'vuex'
import BigNumber from "bignumber.js";
export default {
  components: {
  },
  data() {
    return {
      active: 0,
      whitelist:["Lpzylist","Lpsflist","Lpfxlist","Lpnode"],
      tlist:[
        {times:45,bili:15,type:1},
        {times:90,bili:40,type:2},
        {times:180,bili:90,type:3},
        {times:360,bili:200,type:4},
      ],
      tac:1,
      lpContract:null,
      wkContract:null,
      web3:null,
      bilis:0,
      lpnum:'',
      omtNum:0,
      isloading:false,
      info:{}
    };
  },
  created(){
    this.getSf()
    this.getBili()
  },
  computed: {
    ...mapState(["config"]),
  },
  mounted() {
    let timer = null;
    timer = setInterval(() => {
      if(typeof window.ethereum !== 'undefined' && JSON.stringify(this.config) != "{}"){
        clearInterval(timer)
        this.getcontract()
      }
    }, 200);
  },
  methods: {
    getSf(){
      const user_address = this.$local.get('shop:account');
      this.$http.post('/omt_pledge/releaseTotal',{address:user_address}).then(res=>{
        this.info = res.data
      })
    },
    async lpPledge(){
      this.$toast(this.$t('text.t234'))
      return
      if(this.lpnum === '' || this.lpnum <= 0){
        this.$toast(this.$t('text.t308'))
        return
      }
      const blurRes = this.blurInput()
      console.log(blurRes);
      if(blurRes !== -1) return
      this.isloading = true
      try {
        const sec = this.tlist.find(e=>e.type==this.tac).times
        const miao = sec * 24 * 3600
        // const miao = 300
        await this.checkAllowance(this.lpnum)
        this.wkContract.methods.depositLp(this.web3.utils.toWei(this.lpnum),miao).send().then(res=>{
          this.isloading = false
          console.log(res);
          this.$toast(this.$t('text.t309'))
        }).catch(err=>{
          this.isloading = false
        })
      } catch (error) {
        console.log(error);
        this.isloading = false
      }
    },
    async checkAllowance(destroy){
      const user_address = this.$local.get('shop:account');
			let f_max_num = new BigNumber(2).pow(256).minus(1);  //最大值
			const max_num = f_max_num.toString(16)
			console.log(max_num);
			const isAllowance = await this.lpContract.methods.allowance(user_address,this.config.wk_contract).call();
			console.log(isAllowance);
			console.log(new BigNumber(isAllowance).gte(this.web3.utils.toWei(destroy)));
			if(new BigNumber(isAllowance).gte(this.web3.utils.toWei(destroy))){
				return true
			} else{
				const result1 = await this.lpContract.methods.approve(this.config.wk_contract,'0x'+max_num).send()
				console.log(result1);
        return true
			}
		},
    blurInput(){
      if(this.lpnum.indexOf('.') !== -1){
        const endNum = (this.lpnum.length - 1) - this.lpnum.indexOf('.')
        if(endNum > 8){
          this.$toast(this.$t('text.t310'))
          return endNum
        }
      }
      return -1
    },
    getomtNum(){
      if(this.lpnum === '' || this.lpnum <= 0){
        this.$toast(this.$t('text.t308'))
      } else{
        const amount = this.web3.utils.toWei(this.lpnum)
        this.wkContract.methods.lpWorthOmt(amount).call().then(res=>{
          // console.log(res,this.web3.utils.fromWei(res));
          this.omtNum = this.web3.utils.fromWei(res)
        })
      }
    },
    changeType(e){
      this.tac = e
    },
    golist(){
      if(this.active==0){
        this.goAhead('/lpzylist')
      } else if(this.active==1){
        this.goAhead('/lpsflist')
      }
    },
    getcontract(){
      const user_address = this.$local.get('shop:account');
      const web3 = new this.Web3(ethereum);
      this.web3 = web3
      const contract_lp = this.config.lp_contract;
      const abi_lp = JSON.parse(this.config.lp_abi);
			const lpContract = new web3.eth.Contract(abi_lp, contract_lp,{from:user_address});
      this.lpContract = lpContract

      const contract_wk = this.config.wk_contract;
      const abi_wk = JSON.parse(this.config.wk_abi);
			const wkContract = new web3.eth.Contract(abi_wk, contract_wk,{from:user_address});
      console.log(wkContract);
      this.wkContract = wkContract
    },
    getBili(){
      this.$http.post('/omt_pledge/releaseAllRate').then(res=>{
        this.bilis = res.data
        console.log(this.bilis);
      })
      // const num = await this.wkContract.methods.sumDistributeOmtAmount().call()
      // const nums = this.web3.utils.fromWei(num)
      // const all = await this.wkContract.methods.maxSumDistributeOmtAmount().call()
      // const alls = this.web3.utils.fromWei(all)
      // this.bilis = (nums * 100 / alls).toFixed(6)
      // console.log(this.bilis);
    }
  },
  beforeRouteLeave (to, from, next) {
    console.log(this.whitelist.includes(to.name));
    if(this.whitelist.includes(to.name)){
      from.meta.keepAlive = true
    } else{
      from.meta.keepAlive = false
    }
    next()
  },
};
</script>

<style lang="less">
.lppledge-style {
  .outbox {
    padding: 15px 0 30px;
    .box-card {
      width: 350px;
      border-radius: 20px;
      .van-divider {
        font-size: 9px;
        margin: 11px auto 26px;
      }
      .box1 {
        padding: 0 20px;
        .b1-t1 {
          width: 22px;
          height: auto;
        }
        .b1-t2 {
          font-size: 24px;
          font-weight: bold;
          color: #323232;
        }
      }
      .box2 {
        .van-tab {
          font-size: 18px;
          font-weight: bold;
        }
        .box2-b1 {
          padding: 28px 0 0;
        }
        .b2-i1 {
          width: 20px;
          height: auto;
          margin-right: 10px;
        }
        .b2-t1 {
          font-size: 15px;
          font-weight: bold;
          color: #323232;
        }
        .b2-t2 {
          font-size: 12px;
          font-weight: 500;
          color: #323232;
        }
      }
      .box3 {
        width: 300px;
        box-sizing: border-box;
        height: 50px;
        background: #f4f4f4;
        border-radius: 10px;
        box-shadow: 4px -3px 0 #b2b2b2;
        padding: 0 20px;
        margin-bottom: 10px;
        .b3-input {
          width: 80%;
          border: none;
          background-color: transparent;
          font-size: 12px;
          font-weight: 600;
          color: #909090;
        }
        .b3-t1 {
          font-size: 12px;
          font-weight: 500;
          color: #909090;
        }
      }
      .box4 {
        text-align: right;
        margin-bottom: 38px;
        font-size: 12px;
        font-weight: bold;
        color: #909090;
        padding-right: 20px;
      }
      .box5 {
        height: 40px;
      }
      .box6 {
        width: 290px;
        height: 46px;
        background: #2b2b2b;
        border-radius: 10px;
        box-shadow: 4px -3px 0 #b2b2b2;
        margin: 0 auto 15px;
        font-size: 18px;
        font-weight: bold;
        color: #ffffff;
      }
      .sybox{
        width: 304px;
        height: 67px;
        background: #F4F4F4;
        border-radius: 10px;
        box-shadow: 4px -4px 0 #2B2B2B;
        margin: 0 auto 50px;
        font-size: 15px;
        font-weight: bold;
        color: #323232;
        padding: 0 25px;
        box-sizing: border-box;
      }
      .box7 {
        width: 290px;
        height: 46px;
        background: #ffffff;
        border-radius: 10px;
        box-shadow: 4px -3px 0 #2b2b2b;
        margin: 0 auto 6px;
        font-size: 12px;
        color: #323232;
        border: 1px solid #323232;
        padding: 0 20px;
        box-sizing: border-box;
        .b7-i1 {
          width: 20px;
          height: 20px;
          margin-right: 10px;
        }
        .box7-inp{
          border: none;
          width: 100%;
          height: 80%;
          background-color: transparent;
        }
      }
      .ad-mb24 {
        margin-bottom: 24px;
      }
      .ad-mb13 {
        margin-bottom: 13px;
      }
      .box8 {
        width: 305px;
        font-size: 12px;
        font-weight: bold;
        color: #323232;
        .box8-b1 {
          margin-bottom: 23px;
        }
        .box8-b1:last-of-type {
          margin-bottom: 0;
        }
        .b8-i1 {
          width: 10px;
          height: 10px;
          margin-left: 5px;
        }
      }
      .box9{
        font-size: 12px;
        font-weight: 500;
        color: #2B2B2B;
        text-align: right;
        padding-right: 20px;
        margin-bottom: 26px;
      }
      .box10{
        width: 290px;
        background: #ffffff;
        border-radius: 10px;
        box-shadow: 5px -5px 0 #2b2b2b;
        margin: 0 auto 33px;
        color: #323232;
        border: 1px solid #323232;
        padding: 21px 0 6px;
        box-sizing: border-box;
        .tt1{
          font-size: 15px;
          font-weight: bold;
          padding: 0 15px 16px;
        }
        .tt2{
          font-size: 12px;
          font-weight: bold;
          padding: 15px 24px;
        }
        .tt2-ac{
          background: #F4F4F4;
        }
        .tt3{
          font-size: 18px;
          font-weight: bold;
          padding: 0 26px 9px;
          border-bottom: 1px solid #E1E1E1;
        }
      }
      .bc-box1{
        color: #2B2B2B;
        .t1{
          margin-bottom: 15px;
          .left{
            font-size: 15px;
            font-weight: bold;
          }
          .right{
            font-size: 12px;
            font-weight: bold;
          }
        }
        .t2{
          border: 1px solid #2B2B2B;
          border-radius: 30px;
          overflow: hidden;
          margin-bottom: 15px;
        }
        .t3{
          font-size: 12px;
          font-weight: 500;
        }
      }
    }
  }
}
</style>